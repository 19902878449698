import { EnumToArray } from '../../../utils/enumToArray';

enum Color {
  PrimaryBrand = 'primary-brand',
  PrimarySignature = 'primary-signature',
  SecondaryBrand = 'secondary-brand',
  SupYellow = 'sup-yellow',
  SupGreen = 'sup-green',
  SupRed = 'sup-red',
  SupOrange = 'sup-orange',
  SupGreenLight = 'sup-green-light',
  NeutralDark = 'neutral-dark',
  NeutralLight = 'neutral-light',
  ChartBlue = 'chart-blue',
  ChartPurple = 'chart-purple',
  ChartPink = 'chart-pink',
  ChartOrange = 'chart-orange',
}

enum IconAlign {
  Left = 'left',
  Right = 'right',
}

enum Size {
  Large = 'lg',
  Medium = 'md',
  Small = 'sm',
  ExtraSmall = 'xs',
}

enum Variant {
  Solid = 'solid',
  Line = 'line',
}

enum Kind {
  Badge = 'badge',
  Button = 'button'
}

export const PTZBadgeConfigList = {
  Color: EnumToArray([Color]),
  IconAlign: EnumToArray([IconAlign]),
  Size: EnumToArray([Size]),
  Variant: EnumToArray([Variant]),
  Kind: EnumToArray([Kind])
};

export const PTZBadgeConfig = {
  Color,
  IconAlign,
  Size,
  Variant,
  Kind
};
